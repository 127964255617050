body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "OpenSans",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Merriweather-Light"
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  box-sizing: border-box;
}
html,
body,
#root {
  min-height: 100%;
  overflow: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: 'Merriweather-Light';
  src: local('Merriweather-Light'), url(./fonts/Merriweather-Light.ttf) format('truetype');
}